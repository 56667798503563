import React, { useEffect, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import axios from "axios";
import { Constants } from "../../lib/const/constants";
import ReactPaginate from 'react-paginate';
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function Membership() {
  const navigate = useNavigate();
  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData()
  }, [])
  const getData = async () => {
    setLoading(true);
    const staff_login_id = sessionStorage.getItem('staff_login_id');
    await axios.get(Constants.BASE_URL + 'API-FX-214-LIST-MEMBERSHIP-COMPLIANCE?login_id=' + staff_login_id, {
      headers: {
        fx_key: Constants.SUBSCRIPTION_KEY
      }
    }).then(resp => {
      console.log(resp.data);
      setMemberships(resp.data.workspaces)
      setLoading(false)
    }).catch(err => {
      console.log(err.response.data);
      setLoading(false)
    })
  }

  function Items({ currentItems }) {
    Items.propTypes = {
      currentItems: PropTypes.any,
    };
    return (
      <>
        <table cellPadding={10} cellSpacing={10} className='w-full bg-[#ffffff]' >
          {currentItems &&
            currentItems.map((item, index) => {
              return (
                <>
                  {index == 0 &&
                    <thead className="border-b-2 border-[#f1545e]">
                      <tr>
                        <th scope="col" className="px-6 py-6">Membership No</th>
                        <th scope="col" className="px-6 py-6">Name</th>
                        <th scope="col" className="px-6 py-6">Email</th>
                        <th scope="col" className="px-6 py-6">Phone</th>
                        <th scope="col" className="px-6 py-6">Type</th>
                        <th scope="col" className="px-6 py-6">Account Details</th>
                        <th scope="col" className="px-6 py-6">Action</th>
                      </tr>
                    </thead>}
                  {item.user_account != 'virtual_account' &&
                    <tr key={item.memershipNo} className="border-b-2 border-[#f1545e]">
                      <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">{item.memershipNo}</th>
                      <td className="px-6 py-6">{item.name}</td>
                      <td className="px-6 py-6">{item.email}</td>
                      <td className="px-6 py-6">{item.phone}</td>
                      <td className="px-6 py-6">{item.type}</td>
                      <td className="px-6 py-6">
                        Account No: <b>{JSON.parse(item.clearbank_meta).bank[0].WalletNumber}</b><br/>
                        Sort Code: <b>{JSON.parse(item.clearbank_meta).bank[0].sortCode}</b><br/>
                        IBAN: <b>{JSON.parse(item.clearbank_meta).bank[0].IBAN}</b>
                      </td>
                      <td className="px-6 py-6">
                        <Menu>
                          <MenuButton><HiOutlineCog6Tooth className="text-lg" /></MenuButton>
                          <MenuItems anchor="bottom" className="rounded mt-2  text-[#ffffff]">
                            {/* <MenuItem key="edit" className="px-6 py-1 hover:bg-none">
                            <a className="block" href="javascript:void(0);" onClick={()=>navigate('/memberships/show')}>
                              Show
                            </a>
                          </MenuItem> */}
                            <MenuItem key="edit" className="px-6 py-3 hover:bg-none bg-[#f1545e]">
                              <a className="block" href="javascript:void(0);" onClick={() => navigate('/userDetails', { state: { account_id: item.customer_id } })}>
                                Create Virtual Account
                              </a>
                            </MenuItem>

                            <MenuItem key="edit" className="px-6 py-3 hover:bg-none bg-[#f1545e]">
                              <a className="block" href="javascript:void(0);" onClick={() => navigate('/ListUsers', { state: { account_id: item.customer_id } })}>
                                List Accounts
                              </a>
                            </MenuItem>
                          </MenuItems>
                        </Menu>
                      </td>
                    </tr>
                  }
                </>
              )
            })
          }
        </table>
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    PaginatedItems.propTypes = {
      itemsPerPage: PropTypes.any,
    };
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = memberships.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(memberships.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % memberships.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };
    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          breakLabel="..."
          nextLabel=" next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous "
          renderOnZeroPageCount={null}
          className='flex w-full my-5'
          pageLinkClassName='mx-10'
          activeClassName='text-lg text-[#ffffff] bg-[#f1545e] rounded-lg'
        />
      </>
    );
  }

  return (
    <div className="my-2">

      <div className="relative overflow-x-auto bg-[#ffffff] rounded-3xl">
        {loading ?
          <tr aria-colspan={6}>
            <th scope="col" className="px-6 py-6">loading...</th>
          </tr>
          :
          <PaginatedItems itemsPerPage={10} />
        }
      </div>
      {/* <nav className="flex justify-center my-4">
        <ul className="inline-flex space-x-2 text-sm">
          <li>
            <a href="#"
              className="flex items-center justify-center px-3 h-8 ms-0 text-gray-500 bg-white border rounded-full hover:bg-gray-100">&laquo;</a>
          </li>
          <li>
            <a href="#"
              className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border rounded-full">1</a>
          </li>
          <li>
            <a href="#"
              className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border rounded-full">2</a>
          </li>
          <li>
            <a href="#" aria-current="page"
              className="flex items-center justify-center px-3 h-8 text-custom-sky-[#f1545e] border rounded-full bg-custom-neutral-900">3</a>
          </li>
          <li>
            <a href="#"
              className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border rounded-full">4</a>
          </li>
          <li>
            <a href="#"
              className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border rounded-full">5</a>
          </li>
          <li>
            <a href="#"
              className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border rounded-full">&raquo;</a>
          </li>
        </ul>
      </nav> */}
    </div>
  )
}

export default Membership