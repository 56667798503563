import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Constants } from '../../lib/const/constants';
import getCurrencySymbol from '../../lib/currenyUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FiArrowDownLeft, FiArrowUpRight } from 'react-icons/fi';
import { MdOutlineCurrencyExchange, MdClose } from 'react-icons/md';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ChangeStatusDialog from './Component/ChangeStatusDialog';

function CreditTransactions() {

  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // start with true
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setLoading(false);
    setEdit(false);
  };
  const handleShow = () => {
    setShow(true);

  };
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [beneName, setBeneName] = useState('');
  const [refId, setRefId] = useState('');
  const [beneBankNumber, setBeneBankNumber] = useState('');
  const [beneBankCodeValue, setBeneBankCodeValue] = useState('');
  const [cardFees, setcardFees] = useState('');
  const [paymentMethod, setpaymentMethod] = useState('');
  const [uploadedDocuments, setuploadedDocuments] = useState([]);
  const [fieldName, setFieldName] = useState('');
  const [fieldValue, setFieldValue] = useState('');
  const [flag, setFlag] = useState('');
  const [userNotes, setUserNotes] = useState('');
  const [userAttachment, setuserAttachment] = useState([]);
  const [complianceNotes, setComplianceNotes] = useState('');
  const [complianceAttachment, setcomplianceAttachment] = useState([]);
  const [adminRequestedAttachments, setadminRequestedAttachments] = useState([]);
  const [edit, setEdit] = useState(false);

  const [editfieldName, seteditFieldName] = useState([]);
  const [editfieldValue, seteditFieldValue] = useState([]);
  const [editflag, seteditFlag] = useState();
  const [edituserNotes, seteditUserNotes] = useState();
  const [edituserAttachment, setedituserAttachment] = useState([]);
  const [editcomplianceNotes, seteditComplianceNotes] = useState();
  const [editcomplianceAttachment, seteditcomplianceAttachment] = useState([]);
  const [edittypeOfDocuments, setedittypeOfDocuments] = useState([]);

  const [editfieldSelectedName, seteditFieldSelectedName] = useState();
  const [editfieldSelectedValue, seteditFieldSelectedValue] = useState();
  const [editSelectedflag, seteditSelectedFlag] = useState();
  const [save, setSave] = useState(false);
  const [transactionUserType, setTransactionUserType] = useState();
  const [individual, setIndividual] = useState([]);
  const [business, setBusiness] = useState([]);
  const [changeStatusTransactionId, setChangeStatusTransactionId] = useState();
  const [changeStatusWorkspaceId, setChangeStatusWorkspaceId] = useState();
  const [openStatusDialog, setOpenStatusDialog] = useState(false);

  const getData = async (pagenumber) => {
    const login_id = sessionStorage.getItem('login_id');
    const login_token = sessionStorage.getItem('login_token');
    if (!login_id || !login_token) {
      sessionStorage.clear();
      navigate('/business');
      return;
    }

    setLoading(true); // Start loading
    await axios.get(Constants.BASE_URL + 'API-FX-198-STAFF-CREDIT-TRANSACTIONS?page=' + pagenumber, {
      headers: {
        Authorization: 'Bearer ' + JSON.parse(login_token),
        fx_key: Constants.SUBSCRIPTION_KEY,
      },
    }).then(resp => {
      // console.log(JSON.stringify(resp.data.transactions.data));

      setLoading(false);
      const newTransactions = resp.data.transactions.data;
      if (newTransactions.length > 0) {
        setTransactions(prevTransactions => [...prevTransactions, ...newTransactions]);
        setCurrentPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false); // No more data to load
      }
      setLoading(false);
    }).catch(err => {
      console.error('Error fetching data:', err.response);
      setLoading(false);
    });

    setLoading(true);
    await axios.get(Constants.BASE_URL + 'API-FX-200-FIELD-DETAILS', {
      headers: {
        Authorization: 'Bearer ' + JSON.parse(login_token),
        fx_key: Constants.SUBSCRIPTION_KEY,
      },
    }).then(resp => {
      var fields = [];
      var values = [];
      for (var f = 0; resp.data.fields_details.length > 0; f++) {
        if (resp.data.fields_details[f].field_type == 1)
          fields.push({ 'id': resp.data.fields_details[f].id, 'value': resp.data.fields_details[f].value });
        else if (resp.data.fields_details[f].field_type == 2)
          values.push({ 'id': resp.data.fields_details[f].id, 'value': resp.data.fields_details[f].value });

        seteditFieldName(fields);
        seteditFieldValue(values);
      }
      setLoading(false);
    }).catch(err => {
      console.log(err.response);
      setLoading(false);
    });

    await axios.get(Constants.BASE_URL + 'API-FX-202-DOCUMENTS-LIST', {
      headers: {
        Authorization: 'Bearer ' + JSON.parse(login_token),
        fx_key: Constants.SUBSCRIPTION_KEY,
      },
    }).then(resp => {
      var individual = [];
      var business = [];
      for (var d = 0; d < resp.data.documents.data.length; d++) {
        if (resp.data.documents.data[d].type == 'individual') {
          individual.push(resp.data.documents.data[d]);
        } else if (resp.data.documents.data[d].type == 'business') {
          business.push(resp.data.documents.data[d]);
        }
        setIndividual(individual);
        setBusiness(business);
      }
    }).catch(err => {
      console.log(err.response);
    });
    seteditFlag(['Green', 'Red', 'Blue', 'Yellow', 'Cyan', 'Purple', 'Gold', 'Brown']);
    setedittypeOfDocuments([]);
  };


  useEffect(() => {
    getData(currentPage);
  }, []);

  const navigatetotransaction = async (payment_method, metaDetails, date, transactionid, transaction) => {
    handleShow();
    setChangeStatusTransactionId(transactionid);
    setChangeStatusWorkspaceId(transaction.workspace_id);
    setLoading(true);
    const token = sessionStorage.getItem('login_token');
    await axios.post(Constants.BASE_URL + 'API-FX-196-GET-TRANSACTION-DOCUMENTS', {
      transaction_id: transactionid,
    }, {
      headers: {
        Authorization: 'Bearer ' + JSON.parse(token),
        fx_key: Constants.SUBSCRIPTION_KEY,
      },
    }).then(resp => {
      console.log(resp.data);
      if (resp.data.documents.length > 0)
        setuploadedDocuments(resp.data.documents);
    }).catch(err => {
      console.log(err.response);
    });
    setRefId(transactionid);
    setAmount(metaDetails.exchange_currency + ' ' + metaDetails.recipient_amount);
    setDate(date);
    transaction.type == 'debit' ? setBeneName(metaDetails.second_beneficiary_name) : setBeneName(metaDetails.sender_name);
    setcardFees(metaDetails.card_fees);
    setBeneBankNumber(metaDetails.second_beneficiary_bank_account_number);
    setBeneBankCodeValue(metaDetails.second_beneficiary_bank_code);
    setpaymentMethod(payment_method);
    setFieldName(metaDetails.fieldName);
    setFieldValue(metaDetails.fieldValue);
    setFlag(metaDetails.flag);
    setUserNotes(metaDetails.user_note);
    setComplianceNotes(metaDetails.compliance_note);
    setTransactionUserType(transaction.user_type);

    Object.prototype.hasOwnProperty.call(transaction, 'type_of_documents') ? setadminRequestedAttachments(transaction.type_of_documents) : setadminRequestedAttachments([]);
    Object.prototype.hasOwnProperty.call(metaDetails, 'user_attachment') ? setuserAttachment(metaDetails.user_attachment) : setuserAttachment([]);
    Object.prototype.hasOwnProperty.call(metaDetails, 'compliance_attachment') ? setcomplianceAttachment(metaDetails.compliance_attachment) : setcomplianceAttachment([]);
    setLoading(false);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const handleUserFileChange = (event) => {
    const files = event.target.files;
    const filePromises = [];

    for (let i = 0; i < files.length; i++) {
      filePromises.push(convertFileToBase64(files[i]));
    }

    Promise.all(filePromises)
      .then((base64Files) => {
        setedituserAttachment(base64Files);
      })
      .catch((error) => {
        console.error('Error converting files to Base64:', error);
      });
  };

  const handleComplianceFileChange = (event) => {
    const files = event.target.files;
    const filePromises = [];

    for (let i = 0; i < files.length; i++) {
      filePromises.push(convertFileToBase64(files[i]));
    }

    Promise.all(filePromises)
      .then((base64Files) => {
        seteditcomplianceAttachment(base64Files);
      })
      .catch((error) => {
        console.error('Error converting files to Base64:', error);
      });
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      var extension = (file.name).split('.');
      reader.onload = () => resolve({ 'file': reader.result, 'extension': extension[extension.length - 1] });
      reader.onerror = (error) => reject(error);
    });
  };

  const saveData = async () => {
    setSave(true);
    const login_token = sessionStorage.getItem('login_token');
    console.log({
      'flag': editSelectedflag,
      'user_attachments': edituserAttachment,
      'user_notes': edituserNotes ?? 'NA',
      'compliance_attachments': editcomplianceAttachment,
      'compliance_notes': editcomplianceNotes ?? 'NA',
      'field_name': editfieldSelectedName,
      'field_value': editfieldSelectedValue,
      'type_of_documents': edittypeOfDocuments,
    });

    await axios.post(Constants.BASE_URL + 'API-FX-201-STORE-COMPLIANCE-TRANSACTION-DETAILS/' + refId, {
      'flag': editSelectedflag,
      'user_attachments': edituserAttachment,
      'user_notes': edituserNotes ?? 'NA',
      'compliance_attachments': editcomplianceAttachment,
      'compliance_notes': editcomplianceNotes ?? 'NA',
      'field_name': editfieldSelectedName,
      'field_value': editfieldSelectedValue,
      'type_of_documents': edittypeOfDocuments,
    }, {
      headers: {
        Authorization: 'Bearer ' + JSON.parse(login_token),
        fx_key: Constants.SUBSCRIPTION_KEY,
      },
    }).then(resp => {
      console.log(JSON.stringify(resp.data));
      setEdit(false);
      handleClose();
      setSave(false);
      toast.success('Successfully Saved.');
      // location.reload();
    }).catch(err => {
      console.log(err.response);
      setSave(false);
    });
  };
  return (
    <div className="my-2">
      <ToastContainer />
      <Modal show={show} onHide={handleClose} style={{
        background: '#F5F3F3',
        position: 'absolute',
        width: '90%',
        height: '100%',
        top: '0%',
        right: '0%',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderColor: 'silver',
        borderWidth: 1,
      }}>
        <ChangeStatusDialog
          isOpen={openStatusDialog}
          onClose={() => setOpenStatusDialog(false)}
          transactionId={changeStatusTransactionId}
          workspaceId={changeStatusWorkspaceId}
        />
        <div className="flex justify-between px-4 py-4">
          <div className="text-left font-bold">Transaction Details</div>
          <div className="text-right font-bold space-x-8">
            <span>
                  <button
                    className="text-[#f1545e]"
                    onClick={() => setOpenStatusDialog(true)}
                  >Change Status</button>
                </span>
            <span>Sender Name: {beneName}
                </span>
          </div>
        </div>
        <div style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 15, paddingTop: 15 }}>
          <div style={{
            borderRadius: 10,
            padding: 5,
            borderColor: '#83888f',
            borderWidth: 2,
            background: '#ffffff',
            height: 700,
            overflow: 'scroll',
          }}>
            <Modal.Header closeButton>
              <MdClose onClick={handleClose}
                       style={{ fontSize: 30, top: 0, right: 0, position: 'absolute' }} />
            </Modal.Header>
            <Modal.Body>
              {loading && (<div className="mx-5">loading...</div>)}

              {!loading &&
                <>
                  <Table className="w-full text-sm text-left">
                    <Thead className="">
                      <Tr>
                        <Th scope="col" className="px-4 py-6 font-normal">Reference Id</Th>
                        <Th scope="col" className="px-4 py-6 font-normal">Send Amount</Th>
                        {cardFees != '' && cardFees != null ?
                          <Th scope="col" className="px-4 py-6 font-normal">Fees</Th> : ''}
                        <Th scope="col" className="px-4 py-6 font-normal">Date</Th>
                        {beneBankNumber != '' && beneBankNumber != null ?
                          <Th scope="col" className="px-4 py-6 font-normal">Account
                            Number</Th> : ''}
                        {beneBankCodeValue != '' && beneBankCodeValue != null ?
                          <Th scope="col" className="px-4 py-6 font-normal">Code</Th> : ''}
                        <Th scope="col" className="px-4 py-6 font-normal">Transfer Type</Th>
                      </Tr>
                    </Thead>
                    <Tbody className="table-striped">

                      <Tr>
                        <Td scope="col"
                            className="px-4 py-6 text-[#f1545e] font-bold">{refId}</Td>
                        <Td scope="col"
                            className="px-4 py-6 text-[#f1545e] font-bold">{amount}</Td>
                        {cardFees != '' && cardFees != null ?
                          <Td scope="col"
                              className="px-4 py-6 text-[#f1545e] font-bold">GBP {cardFees}</Td> : ''}
                        <Td scope="col" className="px-4 py-6 text-[#f1545e] font-bold">{date}</Td>
                        {beneBankNumber != '' && beneBankNumber != null ?
                          <Td scope="col"
                              className="px-4 py-6 text-[#f1545e] font-bold">{beneBankNumber}</Td> : ''}
                        {beneBankCodeValue != '' && beneBankCodeValue != null ?
                          <Td scope="col"
                              className="px-4 py-6 text-[#f1545e] font-bold">{beneBankCodeValue}</Td> : ''}
                        <Td scope="col"
                            className="px-4 py-6 text-[#f1545e] font-bold">{paymentMethod}</Td>
                      </Tr>
                    </Tbody>
                  </Table>

                  <div style={{ width: '100%', height: 1, backgroundColor: 'GrayText' }}></div>
                  <Table className="w-full text-sm text-left">
                    <Thead className="=">
                      <Tr>
                        <Th scope="col" className="w-1/4 px-2 py-2 font-normal">Field Name</Th>
                        <Th scope="col" className="w-1/4 px-2 py-2 font-normal">Field Value</Th>
                        <Th scope="col" className="w-1/4 px-2 py-2 font-normal">Flag</Th>
                        <Th scope="col" className="w-1/4 px-2 py-2 font-normal">Requested
                          Documents</Th>
                      </Tr>

                    </Thead>
                    <Tbody className="table-striped">
                      {save &&
                        (<Tr>
                          <Th scope="col" className="px-2 py-2"></Th>
                          <Th scope="col" className="px-2 py-2">Saving...</Th>
                          <Th scope="col" className="px-2 py-2"></Th>
                          <Th scope="col" className="px-2 py-2"></Th>
                        </Tr>)}
                      {!edit ?
                        <Tr>
                          <Td scope="col"
                              className="px-2 py-2 font-bold text-[#f1545e]">{fieldName}</Td>
                          <Td scope="col"
                              className="px-2 py-2 font-bold text-[#f1545e]">{fieldValue}</Td>
                          <Td scope="col"
                              className="px-2 py-2 font-bold text-[#f1545e]">{flag}</Td>

                          <Td scope="col" className="px-2 py-2 font-normal">
                            ----
                          </Td>
                        </Tr>
                        :
                        <>
                          <Tr>
                            <Td scope="col" className="px-2 py-2 font-normal">
                              <select
                                style={{ padding: 5, background: '#F5F3F3', width: '50%' }}
                                value={editfieldSelectedName}
                                onChange={(e) => seteditFieldSelectedName(e.target.value)}>
                                <option>-Name-</option>
                                {
                                  editfieldName.map((resp) => {
                                    return (
                                      <option key={resp.id}
                                              value={resp.value}>{resp.value}</option>
                                    );
                                  })
                                }
                              </select>
                            </Td>
                            <Td scope="col" className="px-2 py-2 font-normal">
                              <select
                                style={{ padding: 5, background: '#F5F3F3', width: '50%' }}
                                value={editfieldSelectedValue}
                                onChange={(e) => seteditFieldSelectedValue(e.target.value)}>
                                <option>-Value-</option>
                                {
                                  editfieldValue.map((resp) => {
                                    return (
                                      <option key={resp.id}
                                              value={resp.value}>{resp.value}</option>
                                    );
                                  })
                                }
                              </select>
                            </Td>
                            <Td scope="col" className="px-2 py-2 font-normal">
                              <select
                                style={{ padding: 5, background: '#F5F3F3', width: '50%' }}
                                value={editSelectedflag}
                                onChange={(e) => seteditSelectedFlag(e.target.value)}>
                                <option>-Flag-</option>
                                {
                                  editflag.map((resp) => {
                                    return (
                                      <option key={resp}>{resp}</option>
                                    );
                                  })
                                }
                              </select>
                            </Td>
                            <Td><select
                              style={{ padding: 5, background: '#F5F3F3', width: '50%' }}
                              multiple onChange={(e) => {
                              const values = [...e.target.selectedOptions].map(opt => opt.value);
                              setedittypeOfDocuments(values);
                            }}
                            >
                              {transactionUserType == 'individual' ? individual.map((resp, index) => {
                                return (
                                  <option key={index}
                                          value={resp.id}>{(resp.document)}</option>
                                );
                              }) : business.map((resp, index) => {
                                return (
                                  <option key={index}
                                          value={resp.id}>{(resp.document)}</option>
                                );
                              })}
                            </select></Td>
                          </Tr>
                        </>
                      }
                    </Tbody>
                  </Table>

                  <Table className="w-full text-sm text-left">
                    {!edit ?
                      <Tbody className="=">
                        <Tr>
                          <Th scope="col" className="px-2 py-2 font-normal">User (Notes &
                            Attachment)
                            <div className="my-4 p-5 border-2 rounded-2xl">
                              {userNotes}
                            </div>
                          </Th>
                          <Td className="w-1/8">
                            <div style={{
                              padding: 10,
                              width: '50%',
                              border: 'dotted',
                              borderColor: '#f1545e',
                              borderRadius: 10,
                              textAlign: 'center',
                            }}>
                              {userAttachment.length > 0 && userAttachment.map((attachment) => {
                                return (
                                  <>
                                    <a rel="noopener noreferrer" target="_blank"
                                       href={Constants.FILE_PATH_BASE_URL + attachment}>View
                                      Document</a><br />
                                  </>
                                );
                              })}
                            </div>
                          </Td>
                        </Tr>
                        <Tr>
                          <Th scope="col" className="px-2 py-2 font-normal">Compliance (Notes &
                            Attachment)
                            <div className="my-4 p-5 border-2 rounded-2xl">
                              {complianceNotes}
                            </div>
                          </Th>
                          <Td>
                            <div style={{
                              padding: 10,
                              width: '50%',
                              border: 'dotted',
                              borderColor: '#f1545e',
                              borderRadius: 10,
                              textAlign: 'center',
                            }}>
                              {complianceAttachment.length > 0 && complianceAttachment.map((attachment) => {
                                return (
                                  <>
                                    <a rel="noopener noreferrer" target="_blank"
                                       href={Constants.FILE_PATH_BASE_URL + attachment}>View
                                      Document</a><br />
                                  </>
                                );
                              })}
                            </div>
                          </Td>
                        </Tr>

                      </Tbody>
                      :
                      <Tbody className="=">
                        <Tr>
                          <Th scope="col" className="px-2 py-2 font-normal">User (Notes &
                            Attachment)
                            <div className="my-4">
                              <input
                                type="text"
                                value={edituserNotes}
                                className="p-2 rounded-l outline text-[#F5F3F3] placeholder:text-sm placeholder:text-slate-400"
                                placeholder="Enter User Notes"
                                style={{ width: '100%' }}
                                onChange={(text) => seteditUserNotes(text.target.value)} />
                            </div>
                          </Th>
                          <Td>
                            <div style={{
                              padding: 10,
                              width: '50%',
                              border: 'dotted',
                              borderColor: '#f1545e',
                              borderRadius: 10,
                              textAlign: 'center',
                            }}>
                              <input
                                type="file"
                                multiple
                                onChange={handleUserFileChange}
                              />
                            </div>
                          </Td>
                        </Tr>
                        <Tr>
                          <Th scope="col" className="px-2 py-2 font-normal">Compliance (Notes &
                            Attachment)
                            <div className="my-4">
                              <input
                                type="text"
                                value={editcomplianceNotes}
                                className="p-2 rounded-l outline text-[#F5F3F3] placeholder:text-sm placeholder:text-slate-400"
                                placeholder="Enter Compliance Notes"
                                style={{ width: '100%' }}
                                onChange={(text) => seteditComplianceNotes(text.target.value)} />
                            </div>
                          </Th>
                          <Td>
                            <div style={{
                              padding: 10,
                              width: '50%',
                              border: 'dotted',
                              borderColor: '#f1545e',
                              borderRadius: 10,
                              textAlign: 'center',
                            }}>
                              <input
                                type="file"
                                multiple
                                onChange={handleComplianceFileChange}
                              />
                            </div>
                          </Td>
                        </Tr>

                      </Tbody>
                    }
                  </Table>
                </>}
              {uploadedDocuments.length > 0 && !loading && (
                <>
                  <div style={{ width: '100%', height: 1, backgroundColor: 'GrayText' }}></div>
                  <div className="font-bold mx-12">
                    User Uploaded Documents
                  </div>
                  <table className="w-full text-sm text-center mx-5">
                    <thead className="border-b-2">
                    <tr>
                      <th>Sno</th>
                      <th>Notes</th>
                      <th>Document</th>
                    </tr>
                    </thead>
                    {uploadedDocuments.map((document, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{document.notes}</td>
                          <td><a rel="noopener noreferrer" target="_blank"
                                 href={Constants.FILE_PATH_BASE_URL + document.document}> View
                            Document</a></td>
                        </tr>
                      );
                    })}
                  </table>
                </>)}

              {adminRequestedAttachments.length > 0 && !loading && (
                <>
                  <div style={{ width: '100%', height: 1, backgroundColor: 'GrayText' }}></div>
                  <div className="font-normal mx-2 my-5">
                    Admin Requested Documents
                  </div>
                  <table className="w-1/2 text-sm text-left mx-5 border-2 mb-10">
                    <thead>
                    <tr style={{ padding: 5, background: '#F5F3F3' }}>
                      <th>Sno</th>
                      <th>Document Type</th>
                      <th>User Document</th>
                    </tr>
                    </thead>
                    {adminRequestedAttachments.map((document, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{document.document}</td>
                          <td>
                            {document.pivot.document != '' && document.pivot.document != null ?
                              <a rel="noopener noreferrer" target="_blank"
                                 href={Constants.FILE_PATH_BASE_URL + document.pivot.document}> View
                                Document</a> : '-'}

                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </>)}

              <div style={{ textAlign: 'center' }}>
                {!loading &&
                  (!edit ?
                      <button onClick={() => setEdit(true)} style={{ width: 150 }}
                              className="bg-[#f1545e] text-white py-2 rounded-xl text-sm">
                        Edit
                      </button>
                      :
                      <>
                        <button onClick={() => {
                          setEdit(false);
                          handleClose();
                          setSave(false);
                        }} style={{ width: 150 }}
                                className="bg-[#f1545e] text-white py-2 rounded-xl text-sm">
                          Cancel
                        </button>
                        <button onClick={() => saveData()} style={{ width: 150 }}
                                className="bg-[#f1545e] text-white py-2 rounded-xl text-sm">
                          Save
                        </button>
                      </>
                  )
                }

              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
      <div className="flex items-center justify-between py-4 mb-4">
        <div className="flex items-center ml-7">
                    <span
                      className="font-medium text-lg border-l-4 border-l-[#F4CE14] pl-3">Credit Transactions</span>
        </div>
        <div className="flex space-x-4">
          {/* <Link to="/send" className="border border-[#f1545e] text-[#f1545e] px-6 py-2 rounded-lg">Send</Link>
          <Link to="/convert" className="border border-[#f1545e] text-[#f1545e] px-6 py-2 rounded-lg">Filters</Link> */}
        </div>
      </div>
      <div className="relative overflow-x-auto rounded-3xl bg-white">
        <InfiniteScroll
          dataLength={transactions.length} // This is the length of the items array
          next={() => getData(currentPage)}
          hasMore={hasMore}
          loader={
            <div className="w-full mt-6">
              <div className="flex justify-center items-center h-5 pb-6">
                {/* <p>Loading transactions...</p> */}
              </div>
            </div>
          }
          endMessage={
            <div className="w-full">
              <div className="flex justify-center items-center h-5 pb-6">
                {/* <p>No more transactions to show</p> */}
              </div>
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <table className="w-full text-sm text-left">
            <thead className="border-b-2">
            <tr>
              <th scope="col" className="px-4 py-6"></th>
              <th scope="col" className="px-4 py-6">Transaction ID</th>
              <th scope="col" className="px-4 py-6">Date & Time</th>
              <th scope="col" className="px-4 py-6">Sender</th>
              <th scope="col" className="px-4 py-6">Receiver</th>
              <th scope="col" className="px-4 py-6">Sending Amount</th>
              <th scope="col" className="px-4 py-6">Receiving Amount</th>
              <th scope="col" className="px-4 py-6">Status</th>
              <th scope="col" className="px-4 py-6">Details</th>
            </tr>
            </thead>
            <tbody className="table-striped">
            {transactions.map((beneficiary) => {
              const meta = beneficiary.meta;
              // const senderName = beneficiary.type == 'debit' ? meta.second_beneficiary_name : meta.sender_name;
              // let splittedSender = senderName.split(";;");
              const receivingAmount = meta.recipient_amount || 'N/A';
              const receivingCurrency = meta.exchange_currency || 'N/A';
              const color = beneficiary.type === 'debit' ? 'bg-gray-400 text-black' : 'bg-green-100 text-green-500';
              return (
                <>
                  <tr key={beneficiary.id}>
                    <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                      <div className={`flex items-center justify-center w-10 h-10 rounded-full ${color}`}>
                        {beneficiary.type == 'debit' ?
                          <FiArrowUpRight fontSize="25px" />
                          :
                          beneficiary.type == null || beneficiary.type == '' || beneficiary.type == 'individual' ?
                            <MdOutlineCurrencyExchange fontSize="25px" />
                            :
                            <FiArrowDownLeft fontSize="25px" />
                        }
                      </div>
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium">
                      {beneficiary.id}</th>
                    <td
                      className="px-4 py-6">{new Date(beneficiary.created_at).getDate() + '-' + (new Date(beneficiary.created_at).getMonth() + 1) + '-' + new Date(beneficiary.created_at).getFullYear()}</td>
                    <td className="px-4 py-6">{meta.sender_name}</td>
                    <td className="px-4 py-6">{beneficiary.workspace.name}</td>
                    <td
                      className="px-4 py-6">{getCurrencySymbol(beneficiary.settled_currency)} {numberWithCommas(beneficiary.amount)}</td>
                    <td
                      className="px-4 py-6">{receivingAmount && getCurrencySymbol(receivingCurrency)} {numberWithCommas(receivingAmount)}
                    </td>
                    <td className="px-4 py-6">

                      {beneficiary.status}
                    </td>
                    <td className="px-4 py-6">
                      <button onClick={() => {
                        navigatetotransaction(beneficiary.payment_method, beneficiary.meta, new Date(beneficiary.created_at).getDate() + '-' + (new Date(beneficiary.created_at).getMonth() + 1) + '-' + new Date(beneficiary.created_at).getFullYear(), beneficiary.id, beneficiary);
                      }}>View Details
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
            </tbody>
          </table>
        </InfiniteScroll>
        {loading &&
          (<div className="w-full mt-6">
            <div className="flex justify-center items-center h-5 pb-6">
              <p>Loading transactions...</p>
            </div>
          </div>)}
        {!loading && hasMore &&
          (<div className="w-full mt-6">
            <div className="flex justify-center items-center h-5 pb-6">
              <button onClick={() => getData(currentPage)}>Load More...</button>
            </div>
          </div>)}
      </div>
    </div>
  );
}

export default CreditTransactions;